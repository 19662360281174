import {useQueries, useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import {SiteModule} from "core/modules/SiteModule";
import {addDays, startOfDay} from "date-fns";
import {fromZonedTime} from "date-fns-tz";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import {SiteSummaryReportDrawerContent} from "./site-summary-report/site-summary-report-drawer-content/SiteSummaryReportDrawerContent";
import {SiteSummaryReportPreviewModal} from "./site-summary-report/site-summary-report-preview-modal/SiteSummaryReportPreviewModal";
import * as Models from "../../../core/models";
import {AnalyticsModule} from "../../../core/modules/AnalyticsModule";
import {DepartmentModule} from "../../../core/modules/DepartmentModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";

type SiteSummaryReportDrawerProps = {
  siteId: string;
  workerReportGroups: Models.WorkerReportGroup[];
  onClose: () => void;
  open: boolean;
  tz_location?: string;
};

export const SiteSummaryReportDrawer: FunctionComponent<SiteSummaryReportDrawerProps> = ({siteId, workerReportGroups, onClose, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const {user: currentUser} = authenticationStore.state;

  const [site, setSite] = useState<Models.Site>();
  const [siteConfig, setSiteConfig] = useState<Models.SiteConfiguration | null>(null);
  const [isReportPreviewModalVisible, setIsReportPreviewModalVisible] = useState(false);
  const [selectedWorkerGroups, setSelectedWorkerGroups] = useState<Models.WorkerReportGroup[]>([]);
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [previewCalled, setPreviewCalled] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<string>(fromZonedTime(new Date(), "UTC").toISOString());
  const [targetUtilization, setTargetUtilization] = useState<boolean>(false);
  const [personalSiteConfig, setPersonalSiteConfig] = useState<{
    active_list_report_included: boolean;
    leaderboard_report_included: boolean;
    weight_offloaded_report_included: boolean;
    safelift_score_report_included: boolean;
    groups: {id: string; name: string}[];
  } | null>(null);

  const endTimeStatus = useCallback(() => {
    return fromZonedTime(startOfDay(addDays(new Date(endTime), 1)), "UTC").toISOString();
  }, [endTime]);
  const endTimeDepartmentStatus = useCallback(() => {
    return fromZonedTime(startOfDay(addDays(new Date(endTime), 1)), "UTC").toISOString();
  }, [endTime]);
  const startTimeStatus = useCallback(() => {
    return fromZonedTime(startOfDay(addDays(new Date(endTime), -7)), "UTC").toISOString();
  }, [endTime]);

  const siteQuery = useQuery({
    queryKey: ["SiteSummartReportDrawer-fetchSiteData", siteId],
    queryFn: () => {
      return SiteModule.site({
        accessToken: authenticationStore.state.accessToken!,
        id: siteId!,
      });
    },
    enabled: !!siteId,
  });

  useEffect(() => {
    if (siteQuery.data?.site) {
      setSite(siteQuery.data.site);
      setSiteConfig(siteQuery.data?.site?.site_configuration);
      setPersonalSiteConfig(
        siteQuery.data?.site?.site_configuration
          ? {
              active_list_report_included: siteQuery.data?.site?.site_configuration.active_list_report_included,
              leaderboard_report_included: siteQuery.data?.site?.site_configuration.leaderboard_report_included,
              weight_offloaded_report_included: siteQuery.data?.site?.site_configuration.weight_offloaded_report_included,
              safelift_score_report_included: siteQuery.data?.site?.site_configuration.safelift_score_report_included,
              groups: siteQuery.data?.site?.site_configuration.groups,
            }
          : null
      );
    }
  }, [siteQuery.data]);

  const analyticsSiteQuery = useQuery({
    queryKey: ["SiteSummaryReportDrawer-fetchDashboardCardData", siteId],
    queryFn: () => {
      appStore.hideAllMessages();

      return AnalyticsModule.analyticsDashboardCard({
        accessToken: authenticationStore.state.accessToken!,
        resource_id: siteId,
        resource_type: Models.EntityType.Site,
        status_interval_bounds: {
          offset_minutes: -new Date().getTimezoneOffset(),
          start_time: startTimeStatus(),
          end_time: endTimeStatus(),
        },
        time_series_config: {
          offset_minutes: -new Date().getTimezoneOffset(),
          end_time: endTimeStatus(),
          interval_type: Models.AnalyticsInterval.Week,
          interval_count: 8,
        },
      });
    },

    enabled: false,
    staleTime: 0,
  });

  const analyticsDashboardTimeSeriesQuery = useQuery({
    queryKey: ["SiteSummaryReportDrawer-fetchTimeSeriesData", siteId],
    queryFn: () => {
      appStore.hideAllMessages();

      return AnalyticsModule.analyticsTimeSeriesData({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteId,
        offset_minutes: -new Date().getTimezoneOffset(),
        end_time: endTimeStatus(),
        interval_type: Models.AnalyticsInterval.Week,
        interval_count: 8,
      });
    },
  });

  useEffect(() => {
    if (site && site.site_configuration && site.site_configuration.groups) {
      const groupIds = site.site_configuration.groups.map((group) => group.id);
      const defaultWorkerGroups = workerReportGroups.filter((group) => groupIds.includes(group.id));

      setSelectedWorkerGroups(defaultWorkerGroups);
    }
  }, [site, workerReportGroups]);

  const siteAnalyticsData = useCallback(() => {
    if (
      analyticsSiteQuery.data &&
      analyticsSiteQuery.data.success &&
      analyticsDashboardTimeSeriesQuery.data &&
      analyticsDashboardTimeSeriesQuery.data.success
    )
      return {
        ...analyticsSiteQuery.data.data,
        time_series_data: analyticsDashboardTimeSeriesQuery.data.data.time_series_data,
      };
    return;
  }, [analyticsSiteQuery, analyticsDashboardTimeSeriesQuery]);

  const queries =
    site && site.departments
      ? site.departments.map((department) => ({
          queryKey: ["SiteSummaryReportDrawer-fetchDashboardCardData", department.id],
          queryFn: () => {
            return AnalyticsModule.analyticsDashboardCard({
              accessToken: authenticationStore.state.accessToken!,
              resource_id: department.id,
              resource_type: Models.EntityType.Department,
              status_interval_bounds: {
                offset_minutes: -new Date().getTimezoneOffset(),
                start_time: startTimeStatus(),
                end_time: endTimeStatus(),
              },
              time_series_config: {
                offset_minutes: -new Date().getTimezoneOffset(),
                end_time: endTimeStatus(),
                interval_type: Models.AnalyticsInterval.Week,
                interval_count: 8,
              },
            });
          },
          enabled: false,
          staleTime: 0,
        }))
      : [];

  const anaylticsDepartmentsQueries = useQueries({queries});

  const queriesTimeSeriesData =
    site && site.departments
      ? site.departments.map((department) => ({
          queryKey: ["SiteSummaryReportDrawer-fetchTimeSeriesData", department.id],
          queryFn: () => {
            return AnalyticsModule.analyticsTimeSeriesData({
              accessToken: authenticationStore.state.accessToken!,
              department_id: department.id,
              offset_minutes: -new Date().getTimezoneOffset(),
              end_time: endTimeStatus(),
              interval_type: Models.AnalyticsInterval.Week,
              interval_count: 8,
            });
          },
        }))
      : [];

  const timeSeriesDataDepartmentsQueries = useQueries({queries: queriesTimeSeriesData});

  const departmentsAnalyticsData = useCallback(() => {
    const results: {id: string; data: Models.AnalyticsDashboardCard}[] = [];
    if (
      site &&
      anaylticsDepartmentsQueries &&
      timeSeriesDataDepartmentsQueries &&
      !anaylticsDepartmentsQueries.some((query) => query.isPending) &&
      !timeSeriesDataDepartmentsQueries.some((query) => query.isPending)
    ) {
      anaylticsDepartmentsQueries.forEach((result, index) => {
        if (result.data && result.data.success) {
          const timeSeriesResult = timeSeriesDataDepartmentsQueries[index];
          if (timeSeriesResult.data && timeSeriesResult.data.success) {
            const combinedData = {
              ...result.data.data,
              time_series_data: timeSeriesResult.data.data.time_series_data,
            };

            results.push({
              id: site.departments![index].id,
              data: combinedData,
            });
          }
        }
      });
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anaylticsDepartmentsQueries, timeSeriesDataDepartmentsQueries]);

  const queriesDepartment =
    site && site.departments
      ? site.departments.map((department) => ({
          queryKey: ["SiteSummaryReportDrawer-fetchDashboardCardWorkersData", department.id],
          queryFn: () => {
            return AnalyticsModule.analyticsDashboardWorkerData({
              accessToken: authenticationStore.state.accessToken!,
              resource_id: department.id,
              resource_type: Models.EntityType.Department,
              end_time: endTimeDepartmentStatus(),
              interval_type: Models.AnalyticsInterval.Day,
              interval_count: 7,
            });
          },
          enabled: !!(
            site.site_configuration &&
            (site.site_configuration.leaderboard_report_included || site.site_configuration.active_list_report_included)
          ),
          staleTime: 0,
        }))
      : [];

  const anaylticsDepartmentsWorkerQueries = useQueries({queries: queriesDepartment});

  const departmentWorkersAnalyticsData = useCallback(() => {
    const results: {id: string; data: Models.AnalyticsDashboardWorkerData}[] = [];
    if (site && anaylticsDepartmentsWorkerQueries && !anaylticsDepartmentsWorkerQueries.some((query) => query.isPending)) {
      anaylticsDepartmentsWorkerQueries.forEach((result, index) => {
        if (result.data && result.data.success) {
          results.push({id: site.departments![index].id, data: result.data.data});
        }
      });
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anaylticsDepartmentsWorkerQueries]);

  const groupQueries = selectedWorkerGroups
    ? selectedWorkerGroups.map((group) => ({
        queryKey: ["SiteSummaryReportDrawer-fetchDashboardCardData", group.id],
        queryFn: () => {
          return AnalyticsModule.analyticsDashboardCard({
            accessToken: authenticationStore.state.accessToken!,
            resource_id: group.id,
            resource_type: Models.EntityType.WorkerReportGroup,
            status_interval_bounds: {
              offset_minutes: -new Date().getTimezoneOffset(),
              start_time: startTimeStatus(),
              end_time: endTimeStatus(),
            },
            time_series_config: {
              offset_minutes: -new Date().getTimezoneOffset(),
              end_time: endTimeStatus(),
              interval_type: Models.AnalyticsInterval.Week,
              interval_count: 8,
            },
          });
        },
        enabled: false,
        staleTime: 0,
      }))
    : [];

  const anaylticsGroupsQueries = useQueries({queries: groupQueries});

  const groupsAnalyticsData = useCallback(() => {
    const results: {id: string; data: Models.AnalyticsDashboardCard}[] = [];
    if (anaylticsGroupsQueries && !anaylticsGroupsQueries.some((query) => query.isPending)) {
      anaylticsGroupsQueries.forEach((result, index) => {
        if (result.data && result.data.success) {
          results.push({id: selectedWorkerGroups[index].id, data: result.data.data});
        }
      });
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anaylticsGroupsQueries]);

  const departmentsQuery = useQuery({
    queryKey: ["SiteSummaryReportDrawer-fetchDepartmentsData", siteId],
    queryFn: () => {
      return DepartmentModule.departments({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteId!,
      });
    },
    enabled: !!siteId,
  });
  useEffect(() => {
    if (departmentsQuery.data && departmentsQuery.data.departments) {
      setTargetUtilization(departmentsQuery.data.departments.every((department) => department.target_type !== Models.TargetType.None));
    }
  }, [departmentsQuery.data]);

  const isFetchingData = useMemo(() => {
    return (
      analyticsSiteQuery.isFetching ||
      anaylticsDepartmentsQueries?.some((query) => query.isFetching) ||
      timeSeriesDataDepartmentsQueries?.some((query) => query.isFetching) ||
      anaylticsGroupsQueries?.some((query) => query.isFetching) ||
      departmentsQuery.isFetching ||
      siteQuery.isFetching ||
      anaylticsDepartmentsWorkerQueries?.some((query) => query.isFetching)
    );
  }, [
    analyticsSiteQuery,
    anaylticsDepartmentsWorkerQueries,
    anaylticsDepartmentsQueries,
    timeSeriesDataDepartmentsQueries,
    anaylticsGroupsQueries,
    siteQuery,
    departmentsQuery.isFetching,
  ]);

  useEffect(() => {
    setFetchingData(isFetchingData!);
    if (!isFetchingData && previewCalled) {
      if (
        (analyticsSiteQuery.data && !analyticsSiteQuery.data?.success) ||
        anaylticsDepartmentsQueries?.some((query) => query.data && !query.data.success) ||
        anaylticsGroupsQueries?.some((query) => query.data && !query.data.success)
      ) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("SiteSummaryReportDrawer-errorMessage"));
      } else {
        setIsReportPreviewModalVisible(true);
      }
      setPreviewCalled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingData]);

  const handleOnPreview = async (endDateFormatted: string) => {
    await setEndTime(endDateFormatted);
    setFetchingData(true);
    setPreviewCalled(true);
    analyticsSiteQuery.refetch();
    anaylticsDepartmentsQueries?.forEach((query) => query.refetch());
    anaylticsDepartmentsWorkerQueries?.forEach((query) => query.refetch());
    anaylticsGroupsQueries?.forEach((query) => query.refetch());
    appStore.sendAnalyticTrack(SegmentKey.SiteSummaryReportView, {
      orgID: site?.organization_id,
      siteID: siteId,
      userOrg: currentUser?.organization_name,
      endDate: endDateFormatted,
      resourceType: Models.EntityType.Site,
      reportGroupCount: selectedWorkerGroups ? selectedWorkerGroups.length : 0,
      leaderboardIncluded: personalSiteConfig?.leaderboard_report_included,
      activeInactiveListIncluded: personalSiteConfig?.active_list_report_included,
    });
  };

  const handleOnChange = async () => {
    await siteQuery.refetch();
    setSite(siteQuery.data?.site);
    if (siteQuery.data && siteQuery.data.site && siteQuery.data.site.site_configuration) {
      setPersonalSiteConfig({
        active_list_report_included: siteQuery.data.site.site_configuration.active_list_report_included,
        leaderboard_report_included: siteQuery.data.site.site_configuration.leaderboard_report_included,
        weight_offloaded_report_included: siteQuery.data.site.site_configuration.weight_offloaded_report_included,
        safelift_score_report_included: siteQuery.data.site.site_configuration.safelift_score_report_included,
        groups: siteQuery.data.site.site_configuration.groups,
      });
    }
  };

  const handlePersonalSettingChange = (
    active_list_report_included: boolean,
    leaderboard_report_included: boolean,
    weight_offloaded_report_included: boolean,
    safelift_score_report_included: boolean,
    groups: {id: string; name: string}[]
  ) => {
    setPersonalSiteConfig({
      active_list_report_included,
      leaderboard_report_included,
      weight_offloaded_report_included,
      safelift_score_report_included,
      groups,
    });
  };

  return (
    <>
      <Drawer
        open={open}
        width={WebHelper.drawerWidth}
        destroyOnClose
        title={WebHelper.formatMessage("SummaryReportDrawer-drawerTitle")}
        onClose={onClose}>
        {site && (
          <SiteSummaryReportDrawerContent
            orgId={site?.organization_id}
            siteId={siteId}
            onChange={handleOnChange}
            workerReportGroups={workerReportGroups}
            siteConfig={siteConfig}
            personalSiteConfig={personalSiteConfig}
            handleChangePersonalSiteConfig={handlePersonalSettingChange}
            setSelectedWorkerReportGroups={setSelectedWorkerGroups}
            loadingDownload={false}
            loadingPreview={fetchingData}
            onPreview={handleOnPreview}
          />
        )}
      </Drawer>
      {site && (
        <SiteSummaryReportPreviewModal
          endDate={endTimeStatus()}
          site={site}
          personalSiteConfig={personalSiteConfig}
          workerReportGroups={selectedWorkerGroups}
          siteAnalyticsData={siteAnalyticsData()}
          siteDepartmentsData={departmentsAnalyticsData()}
          siteGroupsData={groupsAnalyticsData()}
          open={isReportPreviewModalVisible}
          onCancel={() => setIsReportPreviewModalVisible(false)}
          targetUtilization={targetUtilization}
          dataDepartments={departmentWorkersAnalyticsData()}
        />
      )}
    </>
  );
};
export default SiteSummaryReportDrawer;
